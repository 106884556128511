import _ from 'lodash';
import Head from 'next/head';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { useDesktop } from 'src/utils/helper';
// ? TODO:  <AdsNative data={Array} isShowAds={boolean} />

const AdsNative = ({ data, isShowAds }) => {
  const _adsArray = !_.isEmpty(data) ? data : {};

  const isDesktop = useDesktop();
  if (!_.isEmpty(_adsArray)) {
    return (
      <>
        <Head>
          {/* -------------------------------- ADS : Native (Mgid) ------------------------------- */}
          {/* <script
            src='https://jsc.mgid.com/t/n/tnews.co.th.986049.js'
            async
          ></script> */}
          {/* {article.is_show_ads && adsNative?.header && parse(adsNative?.header || '')} */}
          {isShowAds &&
            (isDesktop && data?.header
              ? parse(data?.header || '')
              : !isDesktop && data?.mobile_header
                  ? parse(data?.mobile_header || '')
                  : '')}
        </Head>
        <AdsWrapper className='ads-native-wrapper'>
          {isDesktop
            ? (
            <div id='section-ads' className={`${data?.name}`}>
              {data?.body && (
                <div dangerouslySetInnerHTML={{ __html: data?.body }} />
              )}
            </div>
              )
            : (
            <div id='section-ads' className={`${data?.name}`}>
              {data?.mobile_body && (
                <div dangerouslySetInnerHTML={{ __html: data?.mobile_body }} />
              )}
            </div>
              )}
        </AdsWrapper>
      </>
    );
  }
  return null;
};

const AdsWrapper = styled.div`
  #section-ads {
    max-width: 100%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      @media (max-width: 767px) {
        width: 90%;
      }
    }
  }
  #contents {
    display: flex;
    width: 100%;
    max-width: 95%;
    margin: auto;
  }
`;
export default AdsNative;
